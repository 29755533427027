import api from "./apiSingleton"

export default () => {
  if (process.env.NODE_ENV === "development" || process.env.NODE_ENV === "test") {
    console.log('#### Desenvolvimento ####');
  } else if (process.env.NODE_ENV === "homolog") {
    console.log('#### Homolog ####');
  } else if (process.env.NODE_ENV === "stage") {
      console.log('#### Stage ####');
  } else {
    console.log('#### Produção ####');
  }

  return api
}