<template>
	<router-view></router-view>
</template>

<script>
import Vue from "vue";
import models from "./routes/models.js";
import radiosByUser from "./utils/events/radios-by-user.js";
import Swal from "sweetalert2";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import jwt_decode from "jwt-decode";
const firebaseConfig = {
	apiKey: "AIzaSyA5I8hEFZsrzxjXdOmBgP3K_E_TnX2TKiY",
	authDomain: "api-mobradio.firebaseapp.com",
	databaseURL: "https://api-mobradio.firebaseio.com",
	projectId: "api-mobradio",
	storageBucket: "api-mobradio.appspot.com",
	messagingSenderId: "1050850795324",
	appId: "1:1050850795324:web:b37961a293763084764839",
	measurementId: "G-PPDLLJMYCJ"
};

firebase.initializeApp(firebaseConfig);
export default {
	data() {
		return {
			userId: ""
		};
	},
	methods: {
		getUserId() {
			const user = jwt_decode(localStorage.getItem("SET_APP")).user[0];
			if (user) {
				Promise.resolve(this.getRadiosByUserId(user.id))
					.then(resp => {
						radiosByUser.$emit("Radios", resp.data);
					})
					.catch(e => {
						console.log(e);
					});
			} else {
				// this.$router.push('login')
			}
		},
		async getRadiosByUserId(id) {
			return await models().get(`users/getRadiosByUserId?userId=${id}`);
		}
	},
	mounted() {
		let vm = this;
		window.setInterval(
			function(param) {
				if (
					vm.$route.path !== "/" &&
					vm.$route.path !== "/login" &&
					vm.$route.path !== "/pricing" &&
					vm.$route.path !== "/forms/wizard"
				) {
					const user = jwt_decode(localStorage.getItem("SET_APP")).user[0];
					if (!user) {
						Swal(
							"Atenção",
							"Sua sessão expirou, por favor faça login novamente.",
							"warning"
						);
						sessionStorage.clear();
						localStorage.clear();
						vm.$router.push("login");
					} else {
						const unix_timestamp = jwt_decode(localStorage.getItem("SET_APP"))
							.exp;
						const session = new Date(unix_timestamp * 1000);
						const now = new Date();
						if (now > session) {
							
							Swal(
								"Atenção",
								"Sua sessão expirou, por favor faça login novamente.",
								"warning"
							);
							sessionStorage.clear();
							localStorage.clear();
							vm.$router.push("login");
						}

						// var dt1 = new Date(user.timestamp)
						// var dt2 = new Date()
						// var diff =(dt2.getTime() - dt1.getTime()) / 1000;
						// diff /= 60;
						// if(Math.abs(Math.round(diff)) > 240) {
						//     Swal(
						//         'Atenção',
						//         'Sua sessão expirou, por favor faça login novamente.',
						//         'warning'
						//     )
						//     sessionStorage.clear()
						//     localStorage.clear()
						//     vm.$router.push('login')
						// }
					}
				}
			}.bind(),
			2000
		);

		this.getUserId();
	}
};
</script>

<style>
@import url("//unpkg.com/element-ui@2.4.6/lib/theme-chalk/index.css");
.mx-icon-last-year,
.mx-icon-last-month,
.mx-icon-next-year,
.mx-icon-next-month,
.mx-current-month,
.mx-current-year {
	color: #000 !important;
}
.display-center-container {
	display: flex;
}
.display-center-child {
	align-items: center;
}
.el-input__inner,
.el-textarea__inner {
	margin-top: 15px;
}
.dt-gifts {
	margin-top: 15px;
}
.md-datepicker-overlay,
.md-datepicker-dialog {
	z-index: 999999 !important;
}
.md-table-head-label {
	width: 100%;
}
i.md-icon.md-icon-font.md-icon-image.md-table-sortable-icon.md-theme-default {
	left: 35%;
}
.sidebar-wrapper {
	overflow-x: hidden !important;
}
.sidebar-wrapper::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	border-radius: 10px;
	background-color: #f5f5f5;
}
.sidebar-wrapper::-webkit-scrollbar {
	width: 10px;
	background-color: #f5f5f5;
}

.sidebar-wrapper::-webkit-scrollbar-thumb {
	/* border-radius: 10px; */
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	background-color: #555;
}
@media (max-width: 900px) {
	.el-dialog {
		width: 90vw;
	}
	.mx-input-wrapper {
		margin-top: 10px;
		margin-bottom: 10px;
	}
	i.md-icon.md-icon-font.md-icon-image.md-table-sortable-icon.md-theme-default {
		left: 70%;
	}
}
@media (min-width: 900px) {
	.md-datepicker-overlay,
	.md-datepicker-dialog {
		max-height: 350px !important;
	}
}

@page {
	size: auto;
}

@media print {
	tr {
		page-break-inside: avoid;
	}
	table.report-container {
		page-break-after: always !important;
	}
	thead.report-header {
		display: table-header-group !important;
	}
	tfoot.report-footer {
		display: table-footer-group !important;
		text-align: center;
		font-size: 12pt;
	}
}
</style>
