<template>
    <div class="md-layout">
        <div v-show="loading" class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
            <div class="spinner">
                <div class="rect1"></div>
                <div class="rect2"></div>
                <div class="rect3"></div>
                <div class="rect4"></div>
                <div class="rect5"></div>
            </div>
        </div>
        <div v-show="!loading && blockStatus == 1" class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
            <md-card>
                <md-card-header class="md-card-header-icon md-card-header-blue">
                    <div class="card-icon">
                        <i class="material-icons">thumbs_up_down</i>
                    </div>
                </md-card-header>
                <md-card-content>
                    <div class="md-layout" style="align-items:center">
                        <div class="md-layout-item md-small-size-100 md-medium-size-50 md-large-size-30 md-xlarge-size-25">
                            <h4>Tempo até a próxima atualização: <b>0{{ minutes }}:<b v-show="seconds < 10">{{ auxSeconds }}</b>{{ seconds }}</b></h4>
                        </div>
                        <div class="md-layout-item md-small-size-100 md-medium-size-50 md-large-size-25 md-xlarge-size-20">
                            <md-button class="md-info md-block" @click="()=>{
                                sendMixPanelEvent(`tracking_updated`)
                                updateTracking()
                            }"><i class="fas fa-sync-alt"></i> &nbsp; Atualizar</md-button>
                        </div>
                    </div>
                    <div class="md-layout md-alignment-center-left" style="margin-top: 16px; margin-bottom: 16px;">
                        <h4 class="md-layout-item md-size-100" style="font-weight: 400; font-size: 10pt; font-style: italic; color: #999999; margin: 0;">
                            Última atualização às {{
                                this.lastTimeUpdated.toLocaleTimeString("pt-BR").substring(0, 5)
                            }}
                        </h4>
                    </div>
                </md-card-content>
            </md-card>
        </div>
        <div v-show="!loading && blockStatus == 1" class="md-layout-item md-small-size-100 md-larg-size-100">
            <md-card v-if="info == true">
                <md-card-content>
                    <md-table v-model="tableData" table-header-color="green">
                        <md-table-row slot="md-table-row" slot-scope="{ item }">
                            <!-- <md-table-cell md-label="">{{ item.id }}</md-table-cell> -->
                            <md-table-cell md-label="Título">{{ item.title }}</md-table-cell>
                            <md-table-cell md-label="Artista">{{ item.artist }}</md-table-cell>
                            <md-table-cell md-label="Curti">{{ item.total_likes}} ({{(parseInt(item.percentual_likes))}}%)</md-table-cell>
                            <md-table-cell md-label="Não Curti">{{ item.total_dislikes }} ({{(parseInt(item.percentual_dislikes))}}%)</md-table-cell>
                            <md-table-cell md-label="Total">{{parseInt(item.total_likes) + parseInt(item.total_dislikes)}}</md-table-cell>
                        </md-table-row>
                    </md-table>
                </md-card-content>
            </md-card>
            <md-card v-if="info == false" style="text-align:center">
                <h4>Sem informações no momento.</h4>
            </md-card>
        </div>
        <block v-show="blockStatus == 0"></block>
    </div>
</template>

<script>
import models from '../../routes/models.js'
import selectRadio from '../../utils/events/select-radio.js'
import block from './Block.vue'
import jwt_decode from "jwt-decode";
import mixpanelAdapter from '../../utils/events/mixpanelAdapter';

export default {
    components: {
        block
    },
    data() {
        return {
            lastTimeUpdated: new Date(),
            minutes: 4,
            seconds: 59,
            auxSeconds: 0,
            tableData: [],
            loading: true,
            appKey: null,
            info: false,
            blockStatus: 1,
            blackList: 0
        }
    },
    methods: {
        timer() {
            let self = this
            setInterval(function() {
                if (self.seconds > 0) {
                    self.seconds--
                } else if(self.seconds == 0) {
                    if (self.minutes > 0) {
                        self.minutes--
                    } else if (self.minutes == 0) {
                        self.minutes = 4
                        self.getTracking()
                    }
                    self.seconds = 59
                }
            }, 1000)
        },
        getTracking() {
            this.loading = true
            this.tableData = []
            let self = this
            models().get(`music/getTracking?appKey=${this.appKey}`)
            .then((resp) => {
                this.lastTimeUpdated = new Date()

                if(resp.data.length > 0) {
                    self.info = true
                    self.tableData = resp.data
                    for( var i=self.tableData.length - 1; i>=0; i--){
                        for( var j=0; j<self.blackList.length; j++){
                            if(self.tableData[i] && (self.tableData[i].title === self.blackList[j].name || self.tableData[i].artist === self.blackList[j].name)){
                                self.tableData.splice(i, 1);
                            }
                        }
                    }
                } else {
                    this.info = false
                }
            })
            .catch((e) => {
                console.log(e)
            })
            .finally(() => {this.loading = false})
        },
        updateTracking() {
            this.minutes = 4
            this.seconds = 59
            this.getTracking()
        },
        blockPage() {
                const menu = jwt_decode(localStorage.getItem('APP_02')).services
                let service = null
                for(let obj of menu) {
                    if(obj.subitens === null) {
                        const key = Object.keys(obj.item)[Object.values(obj.item).indexOf(this.$route.path.replace("/", ""))]
                        if(key) {
                            service = obj.item
                        }
                    } else {
                        const key = Object.keys(obj.subitens)[Object.values(obj.subitens).indexOf(this.$route.path.replace("/", ""))]
                        if(key) {
                            service = obj.subitens
                        }
                    }
                }
                
                if(service) {
                    this.blockStatus = service.has_permission
                } else {
                    this.$router.push("dashboard")
                }
            },
        findObjectByKey(array, key, value) {
            for (var i = 0; i < array.length; i++) {
                if (array[i][key] === value) {
                    return array[i];
                }
            }
            return null;
        },
        getBlacklist() {
            models().get(`radios/getBlacklist?appKey=${this.appKey}`).then((resp) => {
                this.blackList = resp.data
            })
        },
        sendMixPanelEvent(eventString){
            mixpanelAdapter.track(eventString, {
                screen_name: this.$route.name
            })
        }
    },
    mounted() {
        this.timer()
        if(jwt_decode(localStorage.getItem('APP_01'))) {
            this.appKey = jwt_decode(localStorage.getItem('APP_01')).radio.key
            this.getBlacklist()
            this.getTracking()
            this.blockPage()
        }
        selectRadio.$on('selectRadio', (payload) => {
            this.appKey = payload.key
            this.getBlacklist()
            this.blockPage()
        })
    },
    watch: {
        appKey() {
            this.getTracking()
            // this.timer()
        }
    }
}
</script>

<style>

</style>


