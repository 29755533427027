import axios from 'axios'
import package_json from '../../package.json'
import jwt_decode from "jwt-decode";

const Swal = require("sweetalert2")

async function triggerErrorRevalidatePopup(){
  await Swal(
    "Erro ao revalidar sessão",
    "Houve algum erro ao revalidar sua sessão. Faça login novamente.",
    "error"
  );
}

async function clearCacheAndRedirect(){
  localStorage.clear()
  sessionStorage.clear()
  window.location.href = "/login"
}

let configObject = {
    baseURL: "https://api.painel.mobradio.com.br/"
}

if (process.env.NODE_ENV === "development" || process.env.NODE_ENV === "test") {
    console.log("SINGLETON")
    console.log(`Versão do painel: ${package_json.version}`)
    console.log('#### Desenvolvimento ####');

    configObject.baseURL = "http://localhost:3003/"

} else if (process.env.NODE_ENV === "homolog") {
    console.log("SINGLETON")
    console.log(`Versão do painel: ${package_json.version}`)
    console.log('#### Homolog ####');

    configObject.baseURL = "https://api.painel.homolog.mobradio.com.br/"
} else if (process.env.NODE_ENV === "stage") {
    console.log("SINGLETON")
    console.log(`Versão do painel: ${package_json.version}`)
    console.log('#### Stage ####');

    configObject.baseURL = "https://api.painel.stage.mobradio.com.br/"
} else {
    console.log("SINGLETON")
    console.log(`Versão do painel: ${package_json.version}`)
    console.log('#### Produção ####');

    configObject.baseURL = "https://api.painel.mobradio.com.br/"
}

const api = axios.create(configObject)

api.interceptors.request.use(
  async config => {
    const userDataLocalStorage = localStorage.getItem('SET_APP')
    if (!userDataLocalStorage) {
      return config
    }

    const userData = await jwt_decode(userDataLocalStorage)
    const lastValidation = userData.lastValidation
    const currentTime = (new Date()).getTime()
    const daysSinceLastValidation = (currentTime - lastValidation) / (1000 * 60 * 60 * 24);

    if (daysSinceLastValidation >= 7) {
      // Revalidate token if it's been more than 1 week
      try {
        const response = await axios.post(`${config.baseURL}users/revalidateToken`, {}, {
          headers: { 'x-access-token': userDataLocalStorage }
        });

        if(response.status === 401){
          await triggerErrorRevalidatePopup()
          await clearCacheAndRedirect()
        }

        if (response.data.auth) {
          localStorage.setItem("SET_APP", response.data.token);
        } else {
          // Handle token revalidation failure
          console.error("Token revalidation failed");
        }
      } catch (err) {
        await triggerErrorRevalidatePopup()
        await clearCacheAndRedirect()
      }
    }

    config.headers['x-access-token'] = `${localStorage.getItem('SET_APP')}`
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

export default api
