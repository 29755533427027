<template>
	<div>
		<div v-if="requestingMobAds" class="class md-layout">
			<Spinner/>
		</div>
		<div v-else class="md-layout hide-on-print" style="padding: 0 15px !important;">
			<md-card style="margin-top: 0;">
				<md-card-content style="padding-top: 25px;">
					<div class="md-layout md-alignment-top-left">
						<div class="md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-30 md-large-size-30 padding-on-medium-screen">
							<el-select 
								clearable ref="mobad-select"
								style="width: 100%;"
								@change="sendMixPanelEvent('mobad_selected')"
								v-model="selectedMobAd" placeholder="Selecione um MobAd...">
								<el-option v-for="(mobad, index) in listOfMobAdsToSelect" :key="index" :label="mobad.name"
									:value="mobad.id">
									{{ new Date(mobad.start_date).toLocaleDateString("pt-BR") }}
									a
									{{ new Date(mobad.end_date).toLocaleDateString("pt-BR") }}
									-
									{{ mobad.name }}
								</el-option>
							</el-select>
						</div>
						<div
							class="md-layout-item md-xsmall-size-100 md-small-size-50 md-medium-size-15 md-large-size-20 md-xlarge-size-20 padding-on-medium-screen">
							<el-date-picker 
								ref="initial-date-input"
								@change="clearSearcher()" v-model="initialDateSearch"
								class="date-picker padding-on-medium-screen-datepicker" placeholder="Data Inicial"
								format="dd/MM/yyyy"></el-date-picker>
						</div>
						<div
							class="md-layout-item md-xsmall-size-100 md-small-size-50 md-medium-size-15 md-large-size-20 md-xlarge-size-20 padding-on-medium-screen">
							<el-date-picker 
								ref="final-date-input"
								v-model="finalDateSearch" @change="clearSearcher()"
								class="date-picker padding-on-medium-screen-datepicker" placeholder="Data Final"
								format="dd/MM/yyyy"></el-date-picker>
						</div>
						<div
							class="md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-20 md-large-size-15 md-xlarge-size-15 padding-on-medium-screen">
							<md-button
								ref="search-button" 
								style="margin-top: 0 !important;"
								class="md-info md-block" 
								@click="searchMobAds()" 
								:disabled="finalDateSearch < initialDateSearch ||
								!finalDateSearch ||
								!initialDateSearch
								">Buscar</md-button>
						</div>
						<div
							class="md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-20 md-large-size-15 md-xlarge-size-15 padding-on-medium-screen">
							<md-button 
								style="margin-top: 0 !important;"
								class="md-success md-block" 
								@click="newMobAd()">Novo</md-button>
						</div>
						<div v-if="finalDateSearch < initialDateSearch &&
							finalDateSearch &&
							initialDateSearch
							" class="md-layout-item md-size-100">
							<i class="fa fa-exclamation-triangle" style="color: #ff0000"></i>&nbsp;
							<span style="color: #ff0000">A data final é anterior à data de início</span>
						</div>
					</div>
					<div v-show="this.errorMessage" class="md-layout">
						<div class="md-layout-item md-size-100">
							<h5>{{ this.errorMessage }}</h5>
						</div>
					</div>
					<div class="md-layout">
						<template v-if="loading">
							<div class="md-layout hide-on-print" v-if="loading">
								<Spinner />
							</div>
						</template>
						<template v-else>
							<div v-if="selectedMobAd" ref="mobad-selected" class="md-layout-item md-size-100">
								<MobAdTab
									:listOfMobAds="this.listOfMobAdsSearched"
								></MobAdTab>
							</div>
							<div v-else class="md-layout-item md-size-100" ref="mobad-tabs">
								<div class="md-layout">
									<div v-if="!this.errorMessage && !this.selectedMobAd" style="padding: 0"
										class="md-layout-item md-size-100">
										<md-field>
											<label>Busca por título</label>
											<md-input type="text" v-model="searchString"></md-input>
										</md-field>
									</div>
								</div>
								<el-tabs style="margin-top: 24px" v-show="!this.errorMessage" v-model="activeName">
									<el-tab-pane label="Ativos" name="first">
										<MobAdTab :listOfMobAds="listOfMobAdsSearched.filter((campaign) => campaign.active)
											" status=" ativos " :searchString="this.searchString" />
									</el-tab-pane>
									<el-tab-pane label="Desativados" name="second">
										<MobAdTab :listOfMobAds="listOfMobAdsSearched.filter(
											(campaign) => !campaign.active
										)
											" status=" desativados " :searchString="this.searchString" />
									</el-tab-pane>
									<el-tab-pane label="Finalizados" name="third">
										<MobAdTab :listOfMobAds="listOfMobAdsSearched.filter(
											(campaign) => campaign.expired === 1
										)
											" status=" finalizados " :searchString="this.searchString" />
									</el-tab-pane>
									<el-tab-pane label="Próximos" name="fourth">
										<MobAdTab :listOfMobAds="listOfMobAdsSearched.filter(
											(campaign) =>
												campaign.current === 0 && campaign.expired !== 1
										)
											" status=" próximos " :searchString="this.searchString" />
									</el-tab-pane>
									<el-tab-pane label="Todos" name="fifth">
										<MobAdTab :listOfMobAds="listOfMobAdsSearched" status=" "
											:searchString="this.searchString" />
									</el-tab-pane>
								</el-tabs>
							</div>
						</template>
					</div>
				</md-card-content>
			</md-card>
		</div>
	</div>
</template>

<script>
import jwt_decode from "jwt-decode";
import MobAdTab from "./MobAdTab";
import MobAdCard from "./MobAdCard.vue"
import Spinner from "../../../components/Icons/Spinner.vue";
import { Button, DatePicker, Switch, Tabs, TabPane } from "element-ui";
import apiSingleton from "../../../routes/apiSingleton"
import selectRadio from "../../../utils/events/select-radio";
import formatDateToMYSQL from "../../../utils/formatDateToSQL";
import Swal from "sweetalert2";
import mixpanelAdapter from "../../../utils/events/mixpanelAdapter";

async function getMobAds(appKey, initialDate, finalDate) {
	initialDate = formatDateToMYSQL(initialDate);
	finalDate = formatDateToMYSQL(finalDate);

	return apiSingleton.get(
		`mobads/getMobAdsByPeriod?appKey=${appKey}&initialDate=${initialDate}&finalDate=${finalDate}`
	);
}

export default {
	components: {
		[DatePicker.name]: DatePicker,
		[Button.name]: Button,
		[Switch.name]: Switch,
		[Tabs.name]: Tabs,
		[TabPane.name]: TabPane,
		MobAdTab,
		MobAdCard,
		Spinner
	},
	data() {
		return {
			requestingMobAds: false,
			loading: false,
			activeName: "first",
			searchString: "",
			radio: jwt_decode(localStorage.getItem("APP_01")).radio,
			initialDateSearch: "",
			finalDateSearch: "",
			selectedMobAd: "",
			listOfMobAdsToSelect: [],
			listOfAllMobAds: [],
			listOfMobAdsSearched: [],
			errorMessage: null,
		};
	},
	methods: {
		updateStatusMobAd(appKey, id, status) {
			let formData = {
				appKey: appKey,
				id: id,
				status: status,
			};

			if(formData.status){
				this.sendMixPanelEvent("mobad_activated")
			}else{
				this.sendMixPanelEvent("mobad_deactivated")
			}

			return apiSingleton.post("mobads/updateStatusMobAd", formData);
		},

		clearSearcher(){
			this.selectedMobAd = null
			this.listOfMobAdsSearched = []
			this.listOfAllMobAds = []
			this.errorMessage = "Selecione um MobAd recente ou faça uma busca personalizada.";
		},
		async getMobAdsToSelect() {
			this.requestingMobAds = true
			this.listOfMobAdsToSelect = [];
			await apiSingleton
				.get(`mobads/getAllMobAdsByAppKey?appKey=${this.radio.key}`)
				.then((response) => {

					this.listOfMobAdsToSelect = response.data

					this.listOfMobAdsToSelect.sort((mobAd1, mobAd2)=>{
						let dateMobAd1 = new Date(mobAd1.start_date)
						
						let dateMobAd2 = new Date(mobAd2.start_date)

						return dateMobAd2.getTime() - dateMobAd1.getTime()
					})
					
					this.listOfMobAdsToSelect = this.listOfMobAdsToSelect.slice(0, 12)

					this.requestingMobAds = false
				})
				.catch((error) => {
					console.log(error)
					Swal('Ops!', 'Houve algum erro ao recuperar os MobAds para busca. Caso persistir, entre em contato com o suporte.', 'error')
				});
		},
		sendMixPanelEvent(eventString, params){
			mixpanelAdapter.track(eventString, {
				...params,
				screen_name: this.$route.name
			})
		},
		searchMobAds() {
			this.loading = true;
			this.listOfAllMobAds = [];
			this.errorMessage = null;
			if (!this.initialDateSearch || !this.finalDateSearch) {
				this.errorMessage = "Preencha os campos de data para realizar a busca";
				this.loading = false;
				return;
			}
			getMobAds(this.radio.key, this.initialDateSearch, this.finalDateSearch)
				.then((response) => {
					if(!this.selectedMobAd){
						this.sendMixPanelEvent(`mobads_searched_by_period`)
					}

					if (response.data.length === 0) {
						this.errorMessage =
							"Nenhum MobAd foi encontrado. Selecione um período em que existiram MobAds.";
						this.loading = false;
						return;
					}

					for (let item of response.data) {
						let expired = 0;
						let current = 0;
						let opened = 0;
						let active = item.active === 1 ? true : false;

						const currentDate = new Date().getTime("pt-BR", {
							timeZone: "America/Sao_Paulo",
						});
						let itemStartDate = new Date(
							`${item.start_date.split(",")[0]}, ${item.start_time}`
						).getTime("pt-BR", { timeZone: "America/Sao_Paulo" });
						let itemEndDate = new Date(
							`${item.end_date.split(",")[0]}, ${item.end_time}`
						).getTime("pt-BR", { timeZone: "America/Sao_Paulo" });

						if (currentDate > itemEndDate) {
							expired = 1;
						} else if (
							currentDate >= itemStartDate &&
							currentDate < itemEndDate
						) {
							current = 1;
						}

						this.listOfAllMobAds.push({
							id: item.id,
							name: item.name,
							deliveryLimit: item.delivery_limit,
							start_date: item.start_date,
							end_date: item.end_date,
							start_time: item.start_time,
							end_time: item.end_time,
							expired: expired,
							current: current,
							active: active,
							opened: opened,
						});

						this.listOfAllMobAds.sort((a, b) =>
							!a.active && b.active ? 1 : -1
						);
					}

					if (this.selectedMobAd) {
						this.listOfMobAdsSearched = this.listOfAllMobAds.filter(
							(mobad) => mobad.id === this.selectedMobAd
						);
					} else {
						this.listOfMobAdsSearched = this.listOfAllMobAds;
					}

					let numberOfActiveMobAds = this.listOfMobAdsSearched.filter(
						(mobad) => mobad.active
					).length;

					if (numberOfActiveMobAds === 0) {
						this.activeName = "fifth";
					}

					this.loading = false;
				})
				.catch((err) => {
					console.log(err);
					Swal.fire({
						title: "Ops!",
						html: "Houve algum erro ao recuperar os MobAds. Caso persista, entre em contato com o suporte.",
						type: "error",
					});
				});
		},

		newMobAd() {
			this.$root.$emit("openNewMobAd");
		},
	},
	mounted() {
		this.errorMessage = "Selecione um MobAd recente ou faça uma busca personalizada.";
		this.getMobAdsToSelect();

		this.$root.$on("reloadMobAd", async (payload)=>{
			await this.getMobAdsToSelect()

			this.selectedMobAd = null
			this.initialDateSearch = payload.start_date
			this.finalDateSearch = payload.end_date

			this.searchMobAds()

			const mobAdsToSelectIds = this.listOfMobAdsToSelect.map(mobad => mobad.id)

			if(mobAdsToSelectIds.includes(payload.id)){
				this.selectedMobAd = payload.id
				this.listOfAllMobAds = this.listOfAllMobAds.filter(mobad=>mobad.id === this.selectedMobAd)
				this.listOfMobAdsSearched = this.listOfAllMobAds.filter(mobad=>mobad.id === this.selectedMobAd)

				this.initialDateSearch = null
				this.finalDateSearch = null
			}
		})

		this.$root.$on("switchStatus", (payload) => {
			this.updateStatusMobAd(this.radio.key, payload.id, payload.active);
		});

		selectRadio.$on("selectRadio", async () => {
			this.radio = jwt_decode(localStorage.getItem("APP_01")).radio;
			this.initialDateSearch = new Date(Date.now());
			this.initialDateSearch = new Date(
				this.initialDateSearch.getFullYear(),
				this.initialDateSearch.getMonth(),
				1
			);
			this.finalDateSearch = new Date(
				this.initialDateSearch.getFullYear(),
				this.initialDateSearch.getMonth() + 1,
				0
			);

			this.clearSearcher()

			await this.getMobAdsToSelect();

			this.initialDateSearch = null;
			this.finalDateSearch = null;
		});
	},
	watch: {
		searchString: function (newVal) {
			if (newVal === "") {
				this.listOfMobAdsSearched = this.listOfAllMobAds;
				return;
			}
			this.listOfMobAdsSearched = this.listOfMobAdsSearched.filter(
				(campaign) => {
					let mobadNameLowercased = campaign.name.toLowerCase();
					let stringSearchedLowercased = this.searchString.toLowerCase();

					return mobadNameLowercased.includes(stringSearchedLowercased);
				}
			);
		},
		selectedMobAd: function (mobAdId) {
			this.searchString = "";
			if (!mobAdId) {
				this.clearSearcher()
				return;
			}

			let mobAdSelected = this.listOfMobAdsToSelect.filter(
				(mobad) => mobad.id === mobAdId
			)[0];
			this.initialDateSearch = new Date(mobAdSelected.start_date);
			this.finalDateSearch = new Date(mobAdSelected.end_date);

			this.searchMobAds();

			this.initialDateSearch = null
			this.finalDateSearch = null
		},
	},
};
</script>

<style>
.el-date-editor {
	width: 100% !important;
}

.row-table {
	height: 60px;
}

@media (max-width: 1300px){
	.padding-on-medium-screen {
		padding: 8px !important;
	}

	.padding-on-medium-screen-datepicker .el-input__inner {
		padding-left: 26px !important;
    	padding-right: 10px !important;
	}
}

</style>
