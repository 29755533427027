class Logger {
    static debug(message, optionalParams = ""){
        if(process.env.NODE_ENV !== "production"){
            console.log(message, optionalParams)
        }
    }

    static info(message, optionalParams = ""){
        console.info(message, optionalParams)
    }

    static warn(message, optionalParams = ""){
        console.warn(message, optionalParams)
    }

    static exception(message, optionalParams = ""){
        console.error(message, optionalParams)
    }
}

module.exports = Logger